import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../basics/Icon/Icon';
import styles from './AsideMenu.module.scss';
import AsideMenuItem from './AsideMenuItem/AsideMenuItem';

interface Props {};

const AsideMenu: FC<Props> = () => {
  const [active, setActive] = useState(false);
  const [moveLogo, setMoveLogo] = useState(window.scrollY > 100);
  
  const openIfClosed = () => !active && setActive(true);
  const close = () => setActive(false);
  
  const detectScrollPosition = (e: Event) => {
    const treshold = 100;
    const scrollPosition = window.scrollY;
    if (scrollPosition > treshold) {
      setMoveLogo(true);
    } else {
      setMoveLogo(false);
    }
  };
  
  useEffect(() => {
    window.addEventListener('scroll', detectScrollPosition);
    return () => window.removeEventListener('scroll', detectScrollPosition);
  }, []);
  
  return (
    <div onClick={openIfClosed} className={classNames(styles.wrapper, active && styles.active)}>
      <button className={styles.trigger}>
        <Icon name="arrow-right-s-line" />
      </button>
      <div className={styles.filler}> {/* Shifts content to make the collapse effect work properly */}
        <div className={styles.fillerPadding} />
      </div>
      <div className={styles.aside}>
        <div className={classNames(styles.logoWrapper, moveLogo && styles.moveLogo)}>
          <Link to="/">
            <div className={styles.logoBeta}>
              <img className={styles.emblemVariant} src="https://gouwgent.be/sites/gouwgent.scoutsgroep.be/files/nieuws/26753918_10156230867043754_1578924084_n.jpg" />
            </div>
          </Link>
        </div>
        <menu onClick={close} className={styles.menu}>
          <AsideMenuItem active={active} label="Groepen" to="/groepen" icon="signpost-line" />
          <AsideMenuItem active={active} label="Locaties" to="/locaties" icon="pin-distance-line" />
          <AsideMenuItem active={active} label="Wie-is-wie" to="/medewerkers" icon="group-2-line" />
          <AsideMenuItem active={active} label="Functies" to="/functies" icon="star-line" />
        </menu>
      </div>
      <div className={styles.backdrop} onClick={close} />
    </div>
  )
}

export default AsideMenu;